import React from 'react'
import './notFound.css'

const NotFound = () =>{
    return(
        <div>
            <p>404</p>
            <p>Address Not Found</p>
        </div>
    )
}

export default NotFound